.custom-container{
    padding: 0px 10px;
    overflow: hidden;
}

.custom-container-1 {
  padding: 0 20px;
  padding-bottom: 50px;
}

.title h2 {
  padding: 10px 20px;
  border: solid 1px rgb(218 165 32);
  border-radius: 10px;/* Adjust padding as needed */
   /* Border radius applied only to the right side */
}

.title h2.active {
  background-color: rgb(218 165 32); /* Background color for active tab */
  color: white;
}


section{
    padding-top: 5px;
}

/* Add this CSS to your existing CSS file or create a new one */

.custom-popup-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background:linear-gradient(180deg, #78bd3c -1.68%, #008000 62.12%);
  border-radius: 10px;
  padding: 10px;
  color: white;
  text-align: center;
  z-index: 1001; /* Adjust the z-index to ensure it's higher than the overlay */
}

.custom-popup-content {
  margin-bottom: 5px;
}

.custom-countdown-container {
  display: flex;
  justify-content: center;
}

.custom-countdown-circle {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 1001; /* Ensure the countdown circle is on top of other content */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.5);
  z-index: 999; /* Keep the overlay behind the popup container */
}

.custom-countdown-circle svg {
  width: 100%;
  height: 100%;
}

.custom-countdown-circle circle {
  fill: none;
  stroke: #fff;
  stroke-width: 2;
}

.custom-countdown-circle text {
  font-size: 20px;
  fill: #fff;
}

.card-2 {
  height: 50px;
  width: 50px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgb(16 143 1 / 80%);
  text-align: center;
  background-color: greenyellow;

  /* Center the content */
  display: flex;
  justify-content: center;
  align-items: center;
}



.theme-btn {
  background: linear-gradient(92deg, #076900 -1.68%, #024d07 62.12%);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: calc(16px + 2 * (100vw - 320px) / 1600);
  border-radius: 6px;
}
.theme-btn:hover {
  color: rgb(255, 255, 255);
}
.theme-btn:active {
  background-color: rgba(var(--theme-color), 1);
  color: rgb(255, 255, 255) !important;
  border: 1px solid rgba(var(--theme-color), 1);
}
.card-1{
  background: linear-gradient(92deg, #076900 -1.68%, #024d07 62.12%);
  color: rgb(255, 255, 255);
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
  width: 180px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth-header-1 {
  overflow: hidden;
  position: relative;
  background: linear-gradient(142.56deg, #fc0707 -1.68%, #fd3333 62.12%);
  height: 200px;
 
}

.auth-header-1 a .back-btn {
  color: rgb(255, 255, 255);
  font-size: 22px;
  line-height: 1;
  position: absolute;
  left: 20px;
  top: 50px;
  z-index: 1;
}
[dir=rtl] .auth-header-1 a .back-btn {
  left: unset;
  right: 20px;
}
.auth-header-1 .help-head h2 {
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 18px;
  line-height: 1;
  position: absolute;
  left: 50px;
  top: 53px;
}
[dir=rtl] .auth-header-1 .help-head h2 {
  left: unset;
  right: 50px;
}
.auth-header-1 .img {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
  width: calc(115px + 10 * (100vw - 320px) / 447);
}
[dir=rtl] .auth-header-1 .img {
  left: 20px;
  right: unset;
}
.auth-header-1 .img1 {
  position: absolute;
  right: 10px;
  bottom: -20px;
  z-index: 1;
  width: 175px;
  height: 200px;
}
[dir=rtl] .auth-header-1 .img1 {
  left: 10px;
  right: unset;
}
.auth-header-1 .img2 {
  position: absolute;
  left: 50%;
  bottom: -30px;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  z-index: 0;
  width: 165px;
  height: 200px;
}
.auth-header-1 .auth-content {
  position: absolute;
 
  width: 100%;
  height: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 0 20px 40px;
}
[dir=rtl] .auth-header-1 .auth-content {
  left: unset;
  right: 0;
}
.auth-header-1 .auth-bg {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.auth-header-1 h2 {
  font-size: 22px;
  color: rgb(255, 255, 255);
}
.auth-header-1 h4 {
  color: rgb(255, 255, 255);
  margin-top: 4px;
}

.game-card {
    background-image:url('./assets/theme_asset/back.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .game-info {
    color: #fff;
  }
  
  .game-info h2 {
    margin-top: 0;
  }
  
  .game-info .time {
    font-size: 24px;
  }
  
  .game-info .color {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .token-img {
    transition: transform 0.3s ease; /* Transition for smooth animation */
  }

  .row_np{
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    /* padding-right: calc(var(--bs-gutter-x) * 0.5); */
    /* padding-left: calc(var(--bs-gutter-x) * 0.5); */
    margin-top: var(--bs-gutter-y);
  }
  
  .rotate {
    animation: heartbeat 1.5s ; /* Rotate animation */
  }

  
  .token-container {
    justify-content: center; /* Center the tokens horizontally */
  }
  
  
  .token-container1 {
    display: flex;
    justify-content: center;
  }
  
  .token-data1 {
    display: flex;
  }

  .token-data {
    justify-content: center;
  }
  
  .card {
    margin: 5px;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .token-button {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: #3498db; /* Default color */
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .token-button1 {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: #f30606; /* Default color */
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .token-button2 {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: #53d348; /* Default color */
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .token-big {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: #ffae00; /* Default color */
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .token-small {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
    color: #fff;
    background-color: #970090; /* Default color */
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  
  
  
  @keyframes heartbeat {
    0% {
      transform: scale(1); /* Initial scale */
    }
    50% {
      transform: scale(1.2); /* Scale up */
    }
    100% {
      transform: scale(1); /* Scale back to original size */
    }
  }


  .popup-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 20px;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.6s ease-in; /* Increase the duration for a slower animation */
    transform: translateY(100%);
  }
  
  .popup-container.active {
    transform: translateY(0);
  }

  .blur-background {
    filter: blur(5px);
    overlay: black;
    pointer-events: none;
  }
  

  .popup-form {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.btn-amount,
.btn-multiplier,
.submit-btn,
.close-btn {
  color: white;
  justify-content: center;
  border: none;
  text-align: center;
  border-radius: 5px;
  padding: 3px;
  margin-right: 5px;
  cursor: pointer;
}

.btn-amount:hover,
.btn-multiplier:hover,
.submit-btn:hover,
.close-btn:hover {
  background-color: #2980b9;
}

.quantity-row {
  margin-top: 10px;
}

.quantity-input,
.amount-input {
  width: 40px;
  padding: 5px;
  margin-right: 10px;
}

.button-container {
  
  border-radius: 0 25px 25px 0;
}
  
.quantity-row {
  display: flex;
  align-items: center; /* Align items vertically */
  margin-top: 10px;
}

.quantity-row label {
  flex: 1; /* Take up remaining space */
}

.quantity-row button,
.quantity-row input {
  display: inline-block;
  margin-right: 5px; /* Add space between elements */
}

.loader-section {
  position: fixed; /* Position the loader section relative to the viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Use viewport width to cover the entire width of the screen */
  height: 100vh; /* Use viewport height to cover the entire height of the screen */
  display: flex; /* Use flexbox to center the loader */
  justify-content: center; /* Horizontally center the loader */
  align-items: center;
  background-color: #ffffff; /* Vertically center the loader */
}
.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #008000;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid #4cfa4c;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 

   
.pagination {
  display: flex;
  list-style: none;
  padding: 20px;
  padding-bottom: 60px;
  justify-content: center;
}

.pagination li {
  margin: 0 5px;
}

.pagination a {
  color: #ffffff;
  background-color: #008000; /* Background color for dark mode */
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
}

.pagination a:hover {
  background-color: #008000;
  color: #fff; /* Hover background color for dark mode */
}

.pagination .active a {
  background-color: #008000;
  color: #fff; /* Active page background color for dark mode */
}

.win-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: white; /* Semi-transparent background */
  z-index: 9999; /* Ensure it appears above other content */
}

.win-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}



.withdraw-info {
  border: 2px solid #9300b1;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0px ;
}



.withdraw-details {
  margin-bottom: 10px;
  font-size: 12px;
}

.detail {
  margin-bottom: 5px;
}

.token-text {
  font-size: 0.8em; /* Adjust font size as needed */
  color: #666; /* Adjust color as needed */
  margin-left: 270px; 
  margin-top: -28px;
  position: absolute;
}

.marquee-container {
  width: 100%;
  overflow: hidden;
}

.marquee {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 15s linear infinite; /* Adjust speed and duration as needed */
}

.marquee span {
  padding-right: 20px; /* Adjust spacing between text items */
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.custom-select {
  position: relative;
  display: inline-block;
  width: 100%; /* Adjust as needed */
}

.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding-right: 30px; /* Adjust to make space for the arrow icon */
}

.custom-select .arrow {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust to position the arrow icon */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow icon doesn't interfere with the select */
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: none; /* Initially hidden */
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the popup is on top of other elements */
}

.popup-content {
  background-color: transparent;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.popup-content img {
  width: 400px;
  cursor: pointer; /* Change cursor to pointer */
  border-radius: 10px;
}

.popup-content button {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: goldenrod;
  color: white;
}

/* Deactivate background when popup is active */
body.popup-active {
  overflow: hidden; /* Prevent scrolling */
}

body.popup-active .popup-overlay {
  display: flex; /* Show the overlay */
}

body.popup-active .popup-content {
  display: block; /* Show the popup content */
}

